<template>
    <div class="expand-filter-wrapper content-main payment">
        <!-- 智慧服务/财务管理/薪酬管理-薪酬项目列表 -->
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        @handlerFocus="handlerFocus"
                        @handelClear="handelClear"
                        :closeWidth="850"
                    >
                    </expand-filter>
                    <div class="button-line" v-has-permi="['salaryProject:add','salaryProject:type','salaryProject:recordList']"></div>
                    <div style="white-space: nowrap;">
                    <el-button
                        v-has-permi="['salaryProject:add']"
                        type="primary"
                        @click="addProject"
                    >
                        添加
                    </el-button>
                    <el-button
                        v-has-permi="['salaryProject:type']"
                        type="primary"
                        class="filter-right-button"
                        @click="TypeManagement"
                    >
                        类型管理
                    </el-button>
                    <el-button
                        v-has-permi="['salaryProject:recordList']"
                        type="enquiry"
                        class="filter-right-button"
                        @click="viewUploadRecord"
                    >
                        上传记录
                    </el-button>
                    </div>
                </div>

                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                    >
                        <template #repairCode="{data}">
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="detailOpen(data)"
                                v-if="detailState"
                                >{{ data.name }}</span
                            >
                            <span v-else>{{ data.name }}</span>
                        </template>
                        <template v-slot:operation="slotData">
                            <el-button
                                @click="downloadTemplate(slotData.data)"
                                v-hasPermi="['salaryProject:template']"
                                type="text"
                                style="
                                    color: #3C7FFF;
                                    border: none;
                                    font-size: 14px;
                                    background: none;
                                "
                            >
                                下载模板
                            </el-button>
                            <el-button
                                v-if="slotData.data.sendStatus == '0'"
                                @click="
                                    handlerUploadFormFile(slotData.data, 'send')
                                "
                                v-hasPermi="['salaryProject:import']"
                                style="
                                    color: #363b40;
                                    border: none;
                                    background: none;
                                    padding: 0px 5px;
                                "
                            >
                                上传表单
                            </el-button>
                            <el-button
                                @click="handleCopy(slotData.data)"
                                v-hasPermi="['salaryProject:copy']"
                                style="
                                    color: #363b40;
                                    border: none;
                                    background: none;
                                    padding: 0px 5px;
                                "
                            >
                                复制
                            </el-button>
                            <el-button
                                v-if="slotData.data.sendStatus == '0'"
                                @click="handleEmpty(slotData.data)"
                                v-hasPermi="['salaryProject:empty']"
                                style="
                                    color: #363b40;
                                    border: none;
                                    background: none;
                                    padding: 0px 5px;
                                "
                            >
                                清空
                            </el-button>
                            <el-button
                                v-if="slotData.data.sendStatus == '0'"
                                @click="handleDelete(slotData.data)"
                                v-hasPermi="['salaryProject:delete']"
                                style="
                                    color: #363b40;
                                    border: none;
                                    background: none;
                                    padding: 0px 5px;
                                "
                            >
                                删除
                            </el-button>
                        </template>
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList(true)"
                    />
                </div>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
        <dialog-wrapper
            style="max-height: calc(100% - 300px); margin-top: 15vh"
            :dialogObj="dialogObj"
            @handleClose="handleClose"
        >
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-position="right"
                label-width="94px"
				class="common-form"
            >
                <el-form-item
                    label="项目名称"
                    prop="name"
                >
                    <el-input
                        v-model="ruleForm.name"
                        placeholder="请填写项目名称"
                        show-word-limit
                        maxlength="30"
						class="input-width-medium"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="部门"
                    prop="organList"
                >
                    <el-input
                        v-model="ruleForm.organList"
                        placeholder="请选择部门"
                        readonly
                        @focus="handlerFocusCreateForm"
						class="input-width-medium"
                    ></el-input>
                    <!--  @change="handleChange" -->
                    <!-- <el-cascader
                        v-model="ruleForm.organList"
                        :options="selTreeOptList"
                        :props="{ expandTrigger: 'hover' }"
                       ></el-cascader> -->
                </el-form-item>
                <el-form-item
                    label="所属月份"
                    prop="month"
                >
                    <el-date-picker
                        v-model="ruleForm.month"
                        type="month"
                        placeholder="选择月"
                        format="yyyy-MM"
                        valueFormat="yyyy-MM"
						class="input-width-medium"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="薪酬类型"
                    prop="mainData"
                >
                    <draggable
                        v-model="ruleForm.mainData"
                        class="list-box"
                        group="people"
                        @start="itemDrag = true"
                        @end="handlerDraggableEnd"
                    >
                        <div
                            v-for="item in ruleForm.mainData"
                            :key="item.id"
                            class="draggableItem"
                        >
                            <el-checkbox
                                v-model="item.sel"
                                :disabled="item.disabled"
                                >{{ item.name }}</el-checkbox
                            >
                        </div>
                    </draggable>
                </el-form-item>
                <div style="text-align: right">
                    <el-button
                        @click="handleClose"
                        class="dialog-reset-button"
                        >取消</el-button
                    >
                    <el-button
                        @click="submitForm"
                        type="primary"
                        >创建</el-button
                    >
                </div>
            </el-form>
        </dialog-wrapper>
        <dialog-wrapper
            :dialogObj="dialogUploadRecordObj"
            @handleClose="handleUploadRecordClose"
        >
            <div class="upload-record-wrapper"></div>
        </dialog-wrapper>
        <dialog-wrapper
            :dialogObj="viewUploadHistory"
            @handleClose="viewUploadHistoryClose"
        >
            <upload-history :list="uploadHistoryList"></upload-history>
            <pagination
                :total="uploadHistoryTotal"
                :page.sync="uploadRecord.pageNum"
                :limit.sync="uploadRecord.pageRow"
                @pagination="getListHistoryTotal"
            />
            <!-- View upload history -->
        </dialog-wrapper>
        <xk-tree-shape
            v-if="organizationType"
            title="组织机构"
            :show="organizationType"
            :list="selTreeOptList"
            :selectObjData="selectObjData"
            @getNextLevelTreeList="getNextLevelTreeList"
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </xk-tree-shape>
        <div style="display: none">
            <el-upload
                ref="uploadFormFile"
                action="/api/school/schoolSalaryProject/import"
                :headers="headers"
                :data="uploadForm"
                :multiple="false"
                accept=".xlsx,.xls"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
            >
            </el-upload>
        </div>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    Pagination,
    MTreeSelect,
    DialogWrapper,
} from "common-local";
import draggable from "vuedraggable";
import TableData from "../Sub/TableData";
import {listToTree, removeTreeShapeDialog, downloadFile} from "@/libs/utils.js";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import UploadHistory from "./UploadHistory.vue";
import selectTree from "../Sub/selectTree";
import {mapState} from "vuex";
import {getToken} from "@/libs/auth";
import {SalaryProjectModel} from "@/models/SalaryProject.js";
import {CommonModel} from "@/models/Common.js";
import onResize from "@/mixins/onResize"
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    mixins: [onResize],
    name: "SalaryProjectList",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
        draggable,
        DialogWrapper,
        UploadHistory,
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                organIds: [], // 部门id
                name: "", // 薪酬项目名称
                startTime: "", // 开始时间
                endTime: "", // 结束时间
                sendStatus: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入项目名称",
                        key: "name",
                    },
                    {
                        type: "cascader",
                        placeholder: "部门",
                        key: "organIds",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            children:"child",
                            multiple: true,
                            value: "id",
                            checkStrictly: true,
                            emitPath: false,
                            expandTrigger: 'hover'
                        },
                    },

                    // {
                    //     type: "organization",
                    //     label: "",
                    //     value: "",
                    //     placeholder: "部门",
                    //     key: "organId",
                    //     list: [],
                    // },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择状态",
                        key: "sendStatus",
                        list: [
                            {
                                label: "已发放",
                                value: 1,
                            },
                            {
                                label: "未发放",
                                value: 0,
                            },
                        ],
                    },
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "monthrange",
                        format: "yyyy-MM",
                        valueFormat: "yyyy-MM",
                        startPlaceholder: "请选择开始日期",
                        endPlaceholder: "请选择结束日期",
                        // defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["salaryProject:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },
            treeShapeSelected: [], // 头部筛选已选择
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        type: "slot",
                        slotName: "repairCode",
                        fixed: false,
                        align: 'center',
                        labelWidth: '200px'
                    },
                    {
                        label: "所属月份",
                        prop: "newMonth",
                        align: 'center'
                    },
                    {
                        label: "部门",
                        labelWidth: "180px",
                        prop: "organList",
                        type: "tooltipColumn",
                        align: 'center',
                        displayKey: "name",
                    },
                    {
                        label: "已传数据",
                        prop: "sum",
                        align: 'center',
                    },
                    {
                        label: "发放状态",
                        prop: "sendStatus",
                        type: "function",
                        fixed: false,
                        align: 'center',
                        callBack(row) {
                            switch (Number(row.sendStatus)) {
                                case 0:
                                    return `<span style="display:inline-block;width:5px;height:5px;border-radius:50%;background-color:#C8CACC;margin-right:5px;"></span><span>未发放</span>`;
                                case 1:
                                    return `<span style="display:inline-block;width:5px;height:5px;border-radius:50%;background-color:#04CCAB;margin-right:5px;"></span><span>已发放</span>`;
                            }
                        },
                    },
                    {
                        label: "添加人",
                        prop: "createName",
                        align: 'center',
                    },
                    {
                        label: "添加时间",
                        prop: "createTimes",
                        type: "date",
                        align: 'center',
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: 'center',
                        className: 'text-spacing-reduction',
                        labelWidth: '130px'
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            dialogObj: {
                title: "创建薪酬项目",
                dialogVisible: false,
                width: "640px",
            },
            dialogUploadRecordObj: {
                title: "上传记录",
                dialogVisible: false,
                width: "500px",
            },
            ruleFormSend: {
                name: "",
                month: "",
                organList: [],
                typeInfo: [],
                remark: "",
            },
            ruleForm: {
                name: "",
                organList: "",
                month: "",
                mainData: [], // 薪酬类型下拉
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入门类名称",
                        trigger: ["blur", "change"],
                    },
                ],
                organList: [
                    {
                        required: true,
                        message: "请选择所属部门",
                        trigger: ["change", "blur"],
                    },
                ],
                month: [
                    {
                        required: true,
                        message: "请选择所属月份",
                        trigger: ["change", "blur"],
                    },
                ],
                mainData: [
                    {
                        required: true,
                        message: "请选择薪酬类型",
                        // trigger: ["change", "blur"],
                    },
                ],
            },
            total: 0,
            options: [],
            checkedCities: true,
            /**
             * 组织机构筛选
             * */
            organizationType: false,
            selTreeOptList: [], // 树形数据
            selectObjData: [], // 已选树形数据
            treeData: {
                schoolId: "",
                type: "",
                parentId: "",
                name: "",
                organType: "",
            }, // 获取树形数据

            /**
             * 薪酬类型
             * */
            compensationType: {
                schoolId: "",
                dictKey: "",
            },
            mainOriginData: [], // 薪酬类型下拉原始数据
            createdFormTreeShapeSelected: [], // 新建项目类型，组织机构已选。
            uploadForm: {
                id: "",
            },
            /**
             * 上传记录
             * */
            uploadRecord: {
                pageNum: 1,
                pageRow: 10,
            },
            viewUploadHistory: {
                title: "上传记录",
                dialogVisible: false,
                width: "800px",
            },
            uploadHistoryTotal: 0,
            uploadHistoryList: [],
        };
    },
    created() {
        this.initAssignment();
        this.initPageData();
		this.$eventDispatch('setGlobalPageType', 'list')
    },
    mounted() {

    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            schoolId: (state) => state.schoolId,
        }),
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    methods: {
        initAssignment() {
            if (this.permissions.indexOf("salaryProject:detail") !== -1) {
                this.detailState = true;
            }

            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }

            this.treeData.schoolId = this.schoolId;
            this.treeData.type = "organ";
            this.treeData.organType = "1";

            this.compensationType.schoolId = this.schoolId;
            this.compensationType.dictKey = "salary";
        },
        initPageData() {
            this.getOrganTree()
            this.getList();
            this.getCompensationType();
        },
        /**
         * 清理树形数据中孩子节点为空数组的情况
         * @param { Array } arr 待处理的树形数组
         * @param { String } children 孩子节点
         * @returns
         */
        cleanTree(arr,childrenKey = 'children') {
            let result = JSON.parse(JSON.stringify(arr));
            for (let i = 0; i < result.length; i++) {
                const node = result[i];
                if (node[childrenKey] === undefined || node[childrenKey] === null) {
                node[childrenKey] = null;
                } else if (Array.isArray(node[childrenKey]) && node[childrenKey].length === 0) {
                node[childrenKey] = null;
                } else if (Array.isArray(node[childrenKey])) {
                node[childrenKey] = this.cleanTree(node[childrenKey], childrenKey);
                }
            }
            return result;
        },
        // 获取部门筛选项数据值
        getOrganTree(){
            this._fet("/school/schoolOrgan/getDepartmentOrgan", {
                schoolId: this.schoolId,
            }).then((res) => {
                console.log(res,'部门')
                let list1 = res.data.data
                this.formData.data.forEach((item) => {
                    if (item.key === "organIds") {
                        item.list = this.cleanTree(list1,'child')
                    }
                });
            })
        },
        /**
         * @Description: 获取列表接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:44:48
         */
        getList(isPaging) {
            this.loadingTable = true;
            const salaryProjectModel = new SalaryProjectModel();
            let data = {};
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0];
                this.listQuery.endTime = data.dateArr[1];
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            console.log(this.listQuery, "00---");
            salaryProjectModel
                .getCompensationItems(this.listQuery)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.total = res.data.data.totalCount;
                        res.data.data.list.forEach((item) => {
                            if (item.month) {
                                let idx = 0;
                                this.$set(item, 'newMonth', item.month.replace(/[\u4e00-\u9fa5]/g, function(val){
                                    idx ++;
                                    if (idx === 1) {
                                        return '-'
                                    } else {
                                        return ''
                                    }
                                }))
                            } else {
                                this.$set(item, 'newMonth', '-')
                            }

                        })
                        this.table_data = res.data.data.list;
                        console.log(this.table_data, "this.table_data");
                        if (this.table_data.length > 0) {
                            this.table_data.map((i) => {
                                //处理添加时间
                                if (i.createTime) {
                                    let createTimes = this.computedTime(
                                        i.createTime,
                                    );
                                    this.$set(i, "createTimes", createTimes);
                                }
                            });
                            this.$nextTick(() => {
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                            });
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        let recordsList =  new RecordsList()
                        if(recordsList.get(this.$route.name)){
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name);
                        this.loadingTable = false;
                    },0)

                });
        },
        /**
         * @Description: 处理时间格式(添加时间)
         * @DoWhat:当年只显示月日时分，非当年显示年月日时分
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-05 16:09:53
         */
        computedTime(Time) {
            var dateObj = new Date(Time);
            var year = dateObj.getFullYear();
            var month =
                dateObj.getMonth() + 1 < 10
                    ? "0" + (dateObj.getMonth() + 1)
                    : dateObj.getMonth() + 1;
            var day =
                dateObj.getDate() < 10
                    ? "0" + dateObj.getDate()
                    : dateObj.getDate();
            var hour =
                dateObj.getHours() < 10
                    ? "0" + dateObj.getHours()
                    : dateObj.getHours();
            var minute =
                dateObj.getMinutes() < 10
                    ? "0" + dateObj.getMinutes()
                    : dateObj.getMinutes();
            var now = new Date();
            var now_new = now.getFullYear(); //年
            if (year == now_new) {
                return month + "-" + day + " " + hour + ":" + minute;
            } else {
                return (
                    year + "-" + month + "-" + day + " " + hour + ":" + minute
                );
            }
        },
        /**
         * @Description: 获取薪酬类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 17:59:32
         */
        getCompensationType() {
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel
                .getCompensationType(this.compensationType)
                .then((res) => {
                    if (res.data.code == "200") {
                        let data = res.data.data;
                        data = data.sort(this.sortList("sort"));
                        this.mainOriginData = JSON.parse(JSON.stringify(data));
                        this.showData();
                        console.log(
                            this.mainOriginData,
                            this.ruleForm.mainData,
                            "结果",
                        );
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        sortList(tar) {
            return function (item1, item2) {
                let it1 = item1[tar],
                    it2 = item2[tar];
                return it1 - it2;
            };
        },
        showData() {
            const data = JSON.parse(JSON.stringify(this.mainOriginData));
            data.forEach((item) => {
                if (item.isDefault == "1") {
                    this.$set(item, "disabled", true);
                } else {
                    this.$set(item, "disabled", false);
                }
                this.$set(item, "sel", true);
            });

            this.ruleForm.mainData = JSON.parse(JSON.stringify(data));
        },
        /**
         * @Description: 组织机构
         * @DoWhat:获取焦点，打开弹框
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 10:18:10
         */
        async handlerFocus() {
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.selectObjData = this.treeShapeSelected;
            await this.getTreeData();
            this.organizationType = true;
        },
        /**
         * @Description: 部门input框清空事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-10 14:06:30
         */
        handelClear() {
            this.listQuery.organIds = [];
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.treeShapeSelected = [];
            console.log(this.listQuery, "999999999999999");
            console.log(
                this.treeShapeSelected,
                this.selectObjData,
                "7777777777777777777777777",
                this.listQuery,
            );
        },
        /**
         * @Description: 打开详情弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-15 16:35:56
         */
        detailOpen(data) {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$emit("goDetails", data);
        },
        /**
         * @Description: 处理状态数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-15 16:36:06
         */
        getStatus(data) {
            console.log(data, "444556");
            switch (Number(data)) {
                case 1:
                    return `<span style='display:inline-block; width:6px; height:6px; border-radius: 50%; background-color: #C8CACC; margin-right:5px;;margin-bottom: 2px;'></span><span>已发放</span>`;
                case 2:
                    return `<span style='display:inline-block; width:6px; height:6px; border-radius: 50%; background-color: #04CCAB; margin-right:5px;;margin-bottom: 2px;'></span><span>未发放</span>`;
            }
        },
        getOrgList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const list = res.data.data.list;
                    const list1 = list.filter((it) => it.organType == 1);
                    this.options = listToTree(list1, {
                        parentKey: "parentOrg",
                    });
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 查询、重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    console.log(this.formData.data, "this.formData.data");
                    this.formData.data.forEach((item) => {
                        Object.keys(this.listQuery).forEach((subItem) => {
                            if (item.key == subItem) {
                                if(item.key === "organIds" && !item.value) {
                                    this.listQuery[item.key] = [];
                                } else {
                                    this.listQuery[item.key] = item.value;
                                }
                            }
                        });
                    });
                    this.getList();
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        if(item.key === "organIds") {
                            item.value = [];
                        } else {
                            item.value = "";
                        }
                    });
                    this.listQuery = {
                        name: "",
                        organIds: [],
                        startTime: "",
                        endTime: "",
                        sendStatus: "",
                        pageNum: 1,
                        pageRow: 20,
                    };
                    console.log(this.listQuery, "this.listQuery");
                    this.getList();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 创建项目
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:13:16
         */
        addProject() {
            this.dialogObj.dialogVisible = true;
        },
        /**
         * @Description: 关闭创建项目弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-25 11:30:17
         */
        handleClose() {
            this.$refs["ruleForm"].resetFields();
            this.dialogObj.dialogVisible = false;
            this.showData();
            this.ruleFormSend = {
                name: "",
                month: "",
                organList: [],
                typeInfo: [],
                remark: "",
            };
            this.createdFormTreeShapeSelected = [];
        },
        /**
         * @Description: 类型管理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:14:38
         */
        TypeManagement() {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$emit("goTypeManagement");

        },
        /**
         * @Description: 查看上传记录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:18:01
         */
        viewUploadRecord() {
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel
                .checkUploadRecord(this.uploadRecord)
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "查看上传数据");
                        const data = res.data.data;
                        this.viewUploadHistory.dialogVisible = true;
                        this.uploadHistoryList = data.rows;
                        this.uploadHistoryTotal = data.total;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 获取更多上传记录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-08 10:18:34
         * @param {*} data
         */
        getListHistoryTotal(data) {
            this.viewUploadRecord();
        },
        /**
         * @Description: 关闭上传记录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-08 10:15:34
         */
        viewUploadHistoryClose() {
            this.uploadRecord.pageNum = 1;
            this.viewUploadHistory.dialogVisible = false;
        },
        /**
         * @Description: 操作-删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:18:23
         */
        handleDelete(data) {
            this.$confirm("删除后不可恢复, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const salaryProjectModel = new SalaryProjectModel();
                    salaryProjectModel
                        .delCompensationItems({id: data.id})
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("删除成功");
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => {});
        },
        /**
         * @Description: 操作-下载模板
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:47:26
         */
        downloadTemplate(data) {
            downloadFile(
                {
                    url: "/school/schoolSalaryProject/templateDownload",
                    form: {id: data.id},
                },
                () => {},
                () => {},
            );
        },
        /**
         * @Description: 上传文件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-06 09:00:42
         */
        handlerUploadFormFile(data) {
            this.uploadForm.id = data.id;
            this.$refs.uploadFormFile.$refs["upload-inner"].handleClick();
        },
        /**
         * @Description: 上传模版前调用函数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-05 17:04:48
         * @param {*} file
         * @param {*} data
         */
        beforeUpload(file) {
            const fileType = file.name.substring(file.name.lastIndexOf("."));
            const nameLength = file.name.substring(
                0,
                file.name.lastIndexOf("."),
            ).length;
            const isImg = [".xlsx", ".xls"].includes(fileType);
            const is30 = nameLength <= 30;
            const isLt10M = file.size / 1024 / 1024 < 10;
            // console.log(fileType,'fileType',isImg,'isImg',file,'file',nameLength,'file.name.length');
            if (!isImg) {
                this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                return false;
            }
            if (!is30) {
                this.$message.error("上传文件名称不能超过30个字符!");
                return false;
            }
            if (!isLt10M) {
                this.$message.error(`上传文件的大小不能超过10M!`);
                return false;
            }

            return true;
        },
        /**
         * @Description: 上传成功后调用函数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-05 17:05:27
         * @param {*} res
         * @param {*} file
         * @param {*} fileList
         */
        handleSuccess(res, file, fileList) {
            if (res.code === "200" && res.data.errorId) {
                this.getList();
                this.$message.error("上传信息错误，错误信息正在下载。");
                downloadFile(
                    {
                        url: `/school/schoolSalaryProject/error/${res.data.errorId}`,
                        method: "get",
                    },
                    () => {},
                    () => {},
                );
            } else {
                this.getList();
                this.$message.success("上传成功");
            }
        },
        /**
         * @Description: 操作-复制
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:52:12
         */
        handleCopy(data) {
            console.log(data, "复制需要的数据");
            // typeInfo 薪酬类型
            // organList 部门
            let month = data.month,
                year = "年",
                mon = "月";
            month = month.replace(year, "-").replace(mon, "");
            console.log(month, "month");
            this.createdFormTreeShapeSelected = JSON.parse(
                JSON.stringify(data.organList),
            );
            this.ruleFormSend.organList = JSON.parse(
                JSON.stringify(data.organList),
            );
            this.showData();

            // this.ruleForm.name = data.name;
            // this.ruleForm.month = month;
            this.ruleForm.organList = data.organList
                .map((item) => item.name)
                .toString();

            let typeInfo = JSON.parse(
                JSON.stringify(data.typeInfo.map((item) => item.typeId)),
            );
            console.log(
                typeInfo,
                "typeInfo",
                data.typeInfo.map((item) => item.typeId),
            );
            this.ruleForm.mainData.forEach((item) => {
                let type = typeInfo.indexOf(item.id);
                if (type === -1) {
                    this.$set(item, "sel", false);
                }
            });

            this.addProject();
            console.log(
                data,
                this.ruleFormSend,
                this.ruleForm,
                this.ruleForm.mainData,
                "列表赋值",
            );
        },
        /**
         * @Description: 操作-清空
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 16:52:24
         */
        handleEmpty(data) {
            this.$confirm("确认清空?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const salaryProjectModel = new SalaryProjectModel();
                    salaryProjectModel
                        .setCompensationItemsEmpty({id: data.id})
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success("清空成功");
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => {
                    // 取消
                });
        },
        /**
         * @Description: 拖动完成后
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-06 09:28:22
         */
        handlerDraggableEnd(tar) {
            console.log(tar, "22222222222222222222tar");
            // let newIdx = tar.newIndex,
            //     oldIdx = tar.oldIndex;
            //     console.log(newIdx,oldIdx,'newIndex,oldIndex');
            // let oldSort = this.ruleForm.mainData.filter((item, index) => {
            //         return index == newIdx;
            //     })[0].sort,
            //     newSort = this.ruleForm.mainData.filter((item, index) => {
            //         return index == oldIdx;
            //     })[0].sort;
            //     console.log(newSort,oldSort,'newSort,oldSort');

            // this.ruleForm.mainData.forEach((item, index) => {
            //     if (newIdx == index) {
            //         item.sort = newSort;
            //     }

            //     if (oldIdx == index) {
            //         item.sort = oldSort;
            //     }
            // });
            this.ruleForm.mainData.forEach((item, index) => {
                item.sort = index + 1;
            });
            console.log(this.ruleForm.mainData, " this.ruleForm.mainData");
        },
        /**
         * @Description: 获取下一个组织机构
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 10:34:31
         * @param {*} data
         */
        async getNextLevelTreeList(data) {
            const {id, name} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
            console.log(data, "请求完成");
        },
        /**
         * @Description: 获取树形数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 10:35:15
         */
        async getTreeData() {
            const commonModel = new CommonModel();
            await commonModel.getAddressBookList(this.treeData).then((res) => {
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        determine(data) {
            if (this.dialogObj.dialogVisible) {
                this.ruleFormSend.organList = JSON.parse(JSON.stringify(data));
                this.createdFormTreeShapeSelected = JSON.parse(
                    JSON.stringify(data),
                );
                this.ruleForm.organList = data
                    .map((item) => {
                        return item.name;
                    })
                    .toString();
                console.log(this.ruleFormSend.organList, "新建项目组织机构");
            } else {
                this.selectObjData = JSON.parse(JSON.stringify(data));
                this.treeShapeSelected = JSON.parse(JSON.stringify(data));
                this.formData.data.forEach((item) => {
                    if (item.key == "organId") {
                        item.value = data
                            .map((subItem) => {
                                return subItem.name;
                            })
                            .toString();
                    }
                });

                this.listQuery.organIds = data.map((subItem) => {
                    return subItem.id;
                });
            }

            this.closeDialog();
        },
        closeDialog() {
            removeTreeShapeDialog();
            this.organizationType = false;
        },
        /**
         * @Description: 创建薪酬项目打开弹窗
         * @DoWhat: 创建薪酬项目打开弹窗
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-05 09:30:26
         */
        async handlerFocusCreateForm() {
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.selectObjData = this.createdFormTreeShapeSelected;
            await this.getTreeData();
            this.organizationType = true;
        },
        /**
         * @Description: 创建薪酬项目
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 11:54:57
         */
        submitForm() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.ruleFormSend.typeInfo = this.ruleForm.mainData.filter(
                        (item) => item.sel,
                    );
                    this.ruleFormSend.name = this.ruleForm.name;
                    this.ruleFormSend.month = this.ruleForm.month;
                    console.log(this.ruleForm, this.ruleFormSend, "发送数据");

                    const salaryProjectModel = new SalaryProjectModel();
                    salaryProjectModel
                        .createCompensationPrograms(this.ruleFormSend)
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success(res.data.msg);

                                this.handleClose();
                                this.getList();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                    console.log("123");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        /**
         * @Description: 关闭上传接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-06 10:17:41
         */
        handleUploadRecordClose() {},
    },
    watch: {
        "ruleForm.mainData": {
            handler(newVal) {
                console.log(newVal, "mainData");
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>

.filter-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: auto;
    margin-bottom: 10px;
    border-radius: 4px;

    .filter-right-button {
        border-color: transparent;
    }

    .expand-filter {
        padding: 0;

        .date-picker-wrap {
            display: flex;
            align-items: center;

            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
		::v-deep .el-input__inner{
			padding: 0px 10px !important;
		}
    }

    .operation-btn-view {
        flex-shrink: 0;
        padding-right: 0;

        ::v-deep .filter-right-button:hover {
            filter: brightness(1.1);
        }
    }
}

.content-main {
    .el-form-item__label {
        // display: none;
    }

    .content-wrapper .el-date-editor {
        width: 340px !important;
    }

    .filter-bar {
        display: flex;
        padding: 15px 20px;
        border-radius: 6px;
        align-items: center;
        margin-bottom: 10px;
        background-color: #ffffff;
        // justify-content: space-between;

        .filter-item {
            display: flex;
            margin-right: 40px;
            align-items: center;

            span {
                color: #808487;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }

    .filter-head {
        margin-bottom: 0 !important;

        /deep/ .el-form-item__label {
            color: #808487;
            line-height: 36px;
        }
    }

    .table-paging {
        box-sizing: border-box;
        // padding: 0 15px;
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}

/deep/.mSelect {
    display: block;
    max-width: 370px;
}
.upload-history-view{
	margin-bottom: 30px;
}
</style>
<style lang="scss">
.payment{
    .el-cascader__search-input{
        margin:2px 0 2px 11px !important;
    }
}

</style>
