<!--
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-09-27 09:06:26
 * @LastEditors: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @LastEditTime: 2023-09-27 14:06:43
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SalaryProject.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 智慧服务/财务管理/薪酬管理-薪酬项目 -->
    <div>
        <component
            v-bind:is="currentComponent"
            :assetMaintenance="details"
            :assetFlow="typeManagement"
            @goTypeManagement="goTypeManagement"
            @goDetails="goDetails"
            @backList="backList"
            @setPageInitTabs="setPageInitTabs"
        ></component>
    </div>
</template>
<script>
    import List from './SalaryProject/index.vue';//列表页
    import Details from './SalaryProject/Details.vue';//详情页
    import TypeManagement from './SalaryProject/TypeManagement.vue';//类型管理

    export default {
        name: "SalaryProjectScrollWrapper",
        components: {
            List,
            Details,
            TypeManagement
        },
        data () {
            return {
                comList: ['List', 'Details', 'TypeManagement'],
                idx: 0,
                details: {},
                typeManagement: {},
                type: 'list'
            }
        },
        computed: {
            currentComponent () {
                return this.comList[this.idx];
            }
        },
        activated() {
            this.setGlobalPageType(this.type)
        },
        methods: {
            /**
             * goDetails 薪酬项目详情
             * goTypeManagement 类型管理
             * backList 列表页
             * */
            goDetails (data) {
                Object.keys(data).forEach((key) => {
                    this.details[key] = data[key];
                })
                console.log(this.details,'this.assetMaintenance123')
                this.idx = 1;
                this.setGlobalPageType('detail')
            },
            goTypeManagement (data) {
                // Object.keys(data).forEach((key) => {
                //     this.typeManagement[key] = data[key];
                // })

                this.idx = 2;
                this.setGlobalPageType('detail')
            },
            backList () {
                this.idx = 0;
                this.setGlobalPageType('list')
            },
            setGlobalPageType (type) {
                this.type = type
                this.$eventDispatch('setGlobalPageType', type)
            },
            setPageInitTabs(){
                this.setGlobalPageType('list')
                this.idx = 0;
            }
        }
    }
</script>
<style lang="scss" scoped></style>
